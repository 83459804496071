
.stu-train-header {
    background-color: #FFF;
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .stu-header-process {
        margin-left: 39px;
        .process-text {
            color: #1122D8;
            font-size: 24px;
        }
    }
    .stu-header-timestring {
        flex: 1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &::v-deep span {
            color: #FF0101;
            font-size: 20px;
            i {
                margin-right: 10px;
            }
        }
    }
    .stu-avatar-info {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .avatar {
            margin-right: 13px;
        }
        .username {
            color: #333333;
            font-size: 16px;
            margin-right: 15px;
        }
        .to-index {
            cursor: pointer;
            i.iconfont {
                font-size: 20px;
                color: #AAA;
            }
        }
    }
}
.stu-train-main {
    width: 100%;
    height: calc(100vh - 60px);
    &::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}

